// Import our Bootstrap extensions
// Import all plugins
import * as bootstrap from 'bootstrap';

import Alpine from 'alpinejs';
import persist from '@alpinejs/persist'
import Prism from 'prismjs';
import './vendor';

window.Alpine = Alpine;
Alpine.plugin(persist);

document.addEventListener('alpine:init', () => {
    Alpine.data('timeboxbmpData', function () {
        return {
            isDark: this.$persist( true ).as( 'timeboxbmp-darkmode' ),
            isOpenMenu: false,
            currentTab: "first",

            setCurrentTab(tabName) {
                this.currentTab = tabName;
                console.log(tabName);
            },

            getCustomFieldValue(arr, name) {
                return arr.reduce((acc, cur) => {
                    if (cur.key == name) {
                        acc = cur.value;
                    }
                    return acc;
                }, "");
            }
        }
    })
})

Alpine.start();

window.toggleControls = () => {
    var video = document.getElementById('videoElement');
    video.controls = true;
}

window.y = () => {
    var video = document.getElementById('videoElement');
    video.controls = false;
}